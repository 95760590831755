/**
 * ThankYou.jsx
 *
 * @file This component completes the registration with a visual
 * notice for the user, that his/her registration was send to the server.
 * @author Robin Walter <hello@robinwalter.me>
 */

import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import PropTypes from 'prop-types'
import React from 'react'

// internal imports
import { Link } from '../../Link'
import routes from '../../../data/routes.json'

/**
 * This component creates informs the user about the current
 * status of his/her registration, so whether it was sent
 * successfully to the server or if the application encountered any
 * errors.
 *
 * @param {Object} props The component props.
 * @returns {Node} The rendered "Thank You" component.
 */
const ThankYou = ({ className, error }) => {

	if ( error )
		return (
			<DialogContent className={ className ? className : ''} sx={{ mb: 2 }}>
				<DialogContentText align="center" gutterBottom variant="h5">Leider konnte Ihre Anmeldung nicht an den Server gesendet werden</DialogContentText>

				<DialogContentText paragraph>Leider müssen wir Ihnen mitteilen, dass Ihre Anmeldung <strong sx={{ color: 'error.main' }}>nicht</strong> an unseren Server gesendet werden konnte!</DialogContentText>

				<DialogContentText variant="body2">Wir hoffen, dass es sich hierbei nur um ein temporäres Problem handelt und bitten Sie daher, es später noch einmal zu probieren. Sollte das Problem weiterhin bestehen, setzen Sie sich bitte über unser <Link to={ routes[ 4 ].path }>Kontaktformular</Link> mit uns in Kontakt.</DialogContentText>
			</DialogContent>
		)

	return (
		<DialogContent className={ className } sx={{ mb: 2 }}>
			<DialogContentText align="center" gutterBottom variant="h5">Vielen Dank für Ihre Anmeldung!</DialogContentText>

			<DialogContentText>Wir möchten Ihnen mitteilen, dass Ihre Anmeldung <strong sx={{ color: 'success.main' }}>erfolgreich</strong> bei uns eingegangen ist!</DialogContentText>

			<DialogContentText paragraph variant="subtitle1">Wie es von hier an weitergeht:</DialogContentText>

			<DialogContentText variant="body2">Die von Ihnen angemeldeten Teilnehmer erhalten nun von uns eine Bestätigungsmail, an die von Ihnen angegebenen E-Mail Adressen.</DialogContentText>
			<DialogContentText color="secondary" paragraph variant="h4"><strong>Bitte</strong> weisen Sie Ihre Teilnehmer darauf hin, dass Sie <strong>innerhalb der nächsten 30 Tage</strong> Ihre Anmeldung über den Link in der E-Mail <strong>bestätigen</strong> müssen! Anderenfalls verfällt die Anmeldung und die personenbezogenen Daten werden aus unserem System automatisch gelöscht.</DialogContentText>
		</DialogContent>
	)
}

/**
 * Define the (expected) prop-types of this component.
 *
 * @typedef {Object} ThankYou.propTypes
 * @property {string} [className] – A CSS class controlling the appearance of this component.
 * @property {Object} [error] – Optional: if the GraphQL server returned an error, this prop will hold the error object.
 */
ThankYou.propTypes = {
	className: PropTypes.string,
	error: PropTypes.object
}

export default ThankYou
