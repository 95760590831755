/**
 * Message.jsx
 *
 * @file This component creates a part of the registration form,
 * so the user is able to send a message along with the participation.
 * @author Robin Walter <hello@robinwalter.me>
 */

import _ from 'lodash'
import Box from '@mui/material/Box'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { useDispatch, useSelector } from 'react-redux'

// internal imports
import {
	changeMessage,
	selectEventRegistrationMessage
} from '../../../state'

/**
 * This component creates the fourth part of the registration form,
 * so the user is able to send a message along with the participation.
 *
 * @param {Object} props The component props.
 * @returns {Node} The rendered form part.
 */
const Message = ({ shallDispatch }) => {

	// Retrieve the state from the store.
	/** Retrieve the current value of the `message` key from the store. */
	const messageStore = useSelector( selectEventRegistrationMessage )

	const dispatch = useDispatch()

	// Define the component states.
	/** Define a state holding the message. */
	const [ message, setMessage ] = useState( messageStore )

	/** Check if the data should be dispatched to the store or update if necessary. */
	useEffect(
		() => {
			if ( shallDispatch ) {
				if ( !_.isEqual( messageStore, message ) ) dispatch( changeMessage( message ) )
			}
		},
		[ message, shallDispatch ]
	)

	return (
        <Box>
			<TextField
				fullWidth
				helperText="Bitte beachten Sie, dass Ihre Nachricht erst nach dem Ende der Anmeldefrist gelesen wird."
				id="registration-message"
				label="Möchten Sie uns zur eingegebenen Anmeldung noch etwas mitteilen?"
				multiline
				name="message"
				onChange={ e => { setMessage( e.target.value ) } }
				rows={ 12 }
				maxRows={ 20 }
				type="text"
				value={ message } />
			<Typography paragraph variant="body2">Benutzen Sie für wichtige Mitteilungen daher bitte das Kontaktformular.</Typography>
		</Box>
    )
}

/**
 * Define the (expected) prop-types of this component.
 *
 * @typedef {Object} Message.propTypes
 * @property {boolean} shallDispatch – Decide whether the component state should be dispatched to the store.
 */
Message.propTypes = {
	shallDispatch: PropTypes.bool.isRequired
}

export default Message
