/**
 * transformAppellation.js
 *
 * @file This file exports a function which transform the appellation to a display-friendly value.
 * @author Robin Walter <hello@robinwalter.me>
 */

/**
 * Transform the appellation value, so only the first character is upper cased.
 *
 * @param {string} string The appellation value.
 * @returns {string} The transformed appellation.
 */
export default string => string.charAt( 0 ).toUpperCase() + string.slice( 1 ).toLowerCase()
