/**
 * Participants.jsx
 *
 * @file This component creates a part of the registration form,
 * so the user is able to fill in the information about the participants.
 * @author Robin Walter <hello@robinwalter.me>
 */

import _ from 'lodash'
import Autocomplete from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import Slider from '@mui/material/Slider'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { useDispatch, useSelector } from 'react-redux'
import validator from 'validator'

// internal imports
import {
	changeEvent,
	selectEventRegistrationEvent,
	selectEventRegistrationEvents,
	selectEventRegistrationParticipants,
	selectEventRegistrationType,
	spliceParticipants
} from '../../../state'
import Participant from './Participant'

/**
 * This component creates the third part of the registration form,
 * so the user is able to fill in the information about the participants.
 *
 * @param {Object} props The component props.
 * @returns {Node} The rendered form part.
 */
const Participants = ( { eventId, maxParticipants, shallDispatch } ) => {

	const defaultEvent = {
		eventID: '',
		id: '',
		label: 'Bitte wählen Sie eine Veranstaltung',
		venue: {
			city: '',
			id: '',
			__typename: 'Venue'
		},
		__typename: 'Event'
	}

	// Retrieve the state from the store.
	/** Retrieve the current value for the `event` key from the store. */
	const eventStore = useSelector( selectEventRegistrationEvent )
	/** Retrieve the fetched `events` from the store. */
	const events = useSelector( selectEventRegistrationEvents )
	/** Retrieve the event `type` from the store. */
	const eventType = useSelector( selectEventRegistrationType )
	/** Retrieve the current `participants` from the store. */
	const participants = useSelector( selectEventRegistrationParticipants )

	// Add the default event to the events array, because otherwise the default value
	// can't be found and the website will freeze
	const autoCompleteEvents = [ ...events ]
	autoCompleteEvents.unshift( defaultEvent )

	const dispatch = useDispatch()

	// Define the component states.
	/** Define a state holding the chosen event. */
	const [ event, setEvent ] = useState( eventStore )
	/** Define a state holding the count of participants to registrate. */
	const [ participantCount, setParticipantCount ] = useState( participants.length !== 0 ? participants.length : 1 )

	/**
	 * Callback to handle an changed event.
	 *
	 * @param {Object} event The triggered event object.
	 * @param {Object} newValue The new value for the `event` key.
	 * @param {string} reason The reason why the callback was invoked.
	 */
	const handleEventChanged = ( event, newValue, reason ) => {
		let newEvent = defaultEvent

		if ( reason === 'selectOption' )
			newEvent = newValue

		setEvent( prev => ( {
			...prev,
			value: newEvent
		} ) )
	}

	/**
	 * Check if the `event` isn't empty and inside the `events` array.
	 */
	const validateEvent = () => {
		if (
			!validator.isIn( event.value.eventID, events.map( event => event.eventID ) )
			|| !_.some( events, event.value )
			|| _.isEqual( event.value, defaultEvent )
		)
			setEvent( {
				...event,
				error: true,
				verified: false
			} )

		else
			setEvent( {
				...event,
				error: false,
				verified: true
			} )
	}

	/** Check if there is a default event, that should be chosen for the user. */
	useEffect( () => {
		if ( eventId ) {
			const paramEvent = _.find( events, event => _.isEqual( event.eventID, eventId ) )
			if ( typeof paramEvent !== 'undefined' )
				setEvent( prev => ( {
					...prev,
					error: false,
					value: paramEvent,
					verified: true
				} ) )
		}
	}, [ eventId, events ] )

	/**
	 * Check if the data should be dispatched to the store or updated if necessary.
	 */
	useEffect(
		() => {
			if ( shallDispatch ) {
				validateEvent()
				if ( event.verified ) {
					if ( !_.isEqual( eventStore, event ) ) dispatch( changeEvent( event ) )
				}
				if ( participantCount < participants.length )
					dispatch(
						spliceParticipants( {
							start: participantCount,
							total: ( participants.length - participantCount )
						} )
					)
			}
		},
		[ event.value.id, event.verified, participantCount, participants.length, shallDispatch ]
	)

	let renderedParticipants = []

	for ( let i = 0; i < participantCount; i++ )
		renderedParticipants.push( <Participant index={ i } key={ i } shallDispatch={ shallDispatch } /> )

	return (
        <Box>
			<Typography paragraph>Wen möchten Sie für welche Veranstaltung anmelden?</Typography>
			<Autocomplete
				autoComplete={ false }
				clearOnBlur
				defaultValue={ defaultEvent }
				fullWidth
				getOptionLabel={ option => option.label }
				isOptionEqualToValue={ (option, value) => _.isEqual(option, value) }
				groupBy={ option => !_.isEmpty( option.venue.city ) ? option.venue.city : false }
				handleHomeEndKeys
				id="registration-event"
				name="event"
				onChange={ handleEventChanged }
				options={ autoCompleteEvents }
				renderInput={ params => <TextField error={ event.error } helperText={ event.error ? 'Bitte wählen Sie eine Veranstaltung aus der Liste aus!' : null } label="Veranstaltung" { ...params } /> }
				required
				selectOnFocus
				value={ event.value } />
			<Typography paragraph variant="body2">Bitte geben Sie die Daten vollständig an, damit wir die Anmeldungen verarbeiten können. Achten Sie vor allem auf <b>korrekte E-Mail-Adressen!</b></Typography>
			<Typography variant="caption">Wie viele { eventType.match( /jury/i ) ? 'Personen' : 'Kolleginnen bzw. Kollegen' } möchten Sie anmelden?</Typography>
			<Slider
				aria-labelledby="registration-participants-count"
				defaultValue={ 1 }
				getAriaValueText={ value => `${ value } Teilnehmer/innen` }
				marks
				max={ maxParticipants }
				min={ 1 }
				onChange={ ( e, newValue ) => { setParticipantCount( newValue ) } }
				step={ 1 }
				sx={{ mt: 5 }}
				value={ participantCount }
				valueLabelDisplay="on" />
			<Typography id="registration-participants-count" variant="caption">{ `Wählen Sie einen Wert zwischen 1 und ${ maxParticipants }` }</Typography>
			{ renderedParticipants }
		</Box>
    )
}

/**
 * Define the (expected) prop-types of this component.
 *
 * @typedef {Object} Participants.propTypes
 * @property {string}  [eventId]     – Optional: an event id to use as the default event.
 * @property {boolean} shallDispatch – Decide whether the component state should be dispatched to the store.
 */
Participants.propTypes = {
	eventId: PropTypes.string,
	shallDispatch: PropTypes.bool.isRequired
}

export default Participants
