/**
 * SchulungAnmeldung.jsx
 *
 * @file Anmeldung template page of the web application.
 * @author Robin Walter <hello@robinwalter.me>
 */

import _ from 'lodash'
import Box from '@mui/material/Box'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { graphql } from 'gatsby'
import React, { useMemo } from 'react'
import { Router } from '@reach/router'
import Typography from '@mui/material/Typography'
import { useDispatch, useSelector } from 'react-redux'

// internal imports
import { RegistrationForm } from '../components/EventRegistrationForm'
import {
	resetEventRegistration,
	selectEventRegistrationType,
	setType
} from '../state'

/**
 * This component represents the Schulungen Anmeldung template page of this application.
 *
 * @param {Object} props The component props.
 * @returns {Node} The rendered page.
 */
const SchulungAnmeldung = ({ data }) => {

	const {
		description,
		maxParticipants,
		type
	} = data.eventType

	/** Retrieve the current selected event type from the store. */
	const eventType = useSelector( selectEventRegistrationType )

	const dispatch = useDispatch()

	/** Set the event type and maybe restore the default values, if another type was selected before. */
	useMemo( () => {
		if ( _.isEmpty( eventType ) ) {
			dispatch( setType( type ) )
		}
		else if ( eventType !== type ) {
			dispatch( resetEventRegistration( type ) )
		}
	}, [ dispatch, eventType, type ] )

	return (
		<>
			<GatsbySeo title={ `Anmeldung zur ${ description }` } />
			<Typography align="center" color="primary" gutterBottom variant="h4">{ description }</Typography>

			<Box sx={{ my: 5 }}>
				<Router basepath={ `/schulungen/${ type }/anmeldung` }>
					<RegistrationForm maxParticipants={ maxParticipants } path="/:eventId" />
					<RegistrationForm maxParticipants={ maxParticipants } path="/" />
				</Router>
			</Box>
		</>
	)
}

export default SchulungAnmeldung

export const query = graphql`
query( $id: String! ) {
	eventType( id: { eq: $id } ) {
		description
		maxParticipants
		type
  	}
}
`
