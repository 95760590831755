/**
 * isOdd.js
 *
 * @file This file exports a function which calculates, if a number is odd.
 * @author Robin Walter <hello@robinwalter.me>
 */

/** Check if the given number is odd. */
export default n => {

	// Must be a number
	if ( isNaN( n ) )

		return false

	// Number must not be a float
	if ( ( n % 1 ) !== 0 )

		return false

	// Integer must not be equal to zero
	if ( n === 0 )

		return false

	// Integer must be odd
	return ( n % 2 ) !== 0

}
