/**
 * eventDuration.js
 *
 * @file This file exports a function which calculates the duration of an event.
 * @author Robin Walter <hello@robinwalter.me>
 */

import moment from 'moment'

/**
 * Calculate the duration of an event.
 *
 * @param {Object} start The time when the event starts at the first date.
 * @param {Object} end The time when the event ends at the first date.
 * @param {Object} secondDateEnd The time when the event ends at the second date, if there is a second date.
 * @returns {string} A string describing the duration of the event.
 */
export default ( start, end, secondDateEnd ) =>
	secondDateEnd ?
		'2 Tage'
	:
		moment.duration( start.diff( end ) ).humanize()
