/**
 * eventDeadline.js
 *
 * @file This file exports a function which calculates the deadline of an event.
 * @author Robin Walter <hello@robinwalter.me>
 */

import moment from 'moment'

/**
 * Calculate the deadline of an event.
 *
 * @param {Object} initialRegistrationPeriod The initial registration period of the event.
 * @param {Object} secondRegistrationPeriod The start of the second registration period of the event.
 * @param {Object} periodOfRest The last possible date of registration of the event.
 * @returns {Object} The calculated deadline.
 */
export default ( initialRegistrationPeriod, secondRegistrationPeriod, periodOfRest ) => {

	const now = moment()

	let deadline = null

	if ( now.isBefore( initialRegistrationPeriod ) ) deadline = moment(secondRegistrationPeriod).subtract(1, 'days')

	else if ( now.isAfter( initialRegistrationPeriod ) && now.isBefore( secondRegistrationPeriod ) ) deadline = moment(secondRegistrationPeriod).subtract(1, 'days')

	else deadline = moment(periodOfRest).subtract(1, 'days')

	return deadline

}
