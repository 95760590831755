/**
 * AcceptPrivacyPolicy.jsx
 *
 * @file This component creates a part of the registration form,
 * so the user is able to accept the privacy policy.
 * @author Robin Walter <hello@robinwalter.me>
 */

import Box from '@mui/material/Box'
import FormControlLabel from '@mui/material/FormControlLabel'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import Switch from '@mui/material/Switch'
import { useDispatch, useSelector } from 'react-redux'

// internal imports
import {
	changeAcceptedPrivacyPolicy,
	selectEventRegistrationAcceptedPrivacyPolicy,
	selectEventRegistrationType
} from '../../../state'

/**
 * This component creates the fifth part of the registration form,
 * so the user is able to accept the privacy policy.
 *
 * @param {Object} props The component props.
 * @returns {Node} The rendered form part.
 */
const AcceptPrivacyPolicy = ( { shallDispatch } ) => {

	// Retrieve the state from the store.
	/** Retrieve the current value of the `acceptedPrivacyPolicy` key from the store. */
	const acceptedPrivacyPolicyStore = useSelector( selectEventRegistrationAcceptedPrivacyPolicy )
	/** Retrieve the event `type` from the store. */
	const eventType = useSelector( selectEventRegistrationType )

	const dispatch = useDispatch()

	// Define the component states.
	/** Define a state holding the value of the accepted privacy policy `Switch`. */
	const [ acceptedPrivacyPolicy, setAcceptedPrivacyPolicy ] = useState( acceptedPrivacyPolicyStore || false )

	/** Check if the data should be dispatched to the store or update if necessary. */
	useEffect(
		() => {
			if ( shallDispatch ) {
				if ( acceptedPrivacyPolicyStore !== acceptedPrivacyPolicy ) dispatch( changeAcceptedPrivacyPolicy( acceptedPrivacyPolicy ) )
			}
		},
		[ acceptedPrivacyPolicy, shallDispatch ]
	)

	return (
        <Box>
			<FormControlLabel
				control={
					<Switch
						checked={ acceptedPrivacyPolicy }
						color="primary"
						inputProps={ { id: 'registration-accepted-privacy-policy', name: 'acceptedPrivacyPolicy' } }
						onChange={ e => { setAcceptedPrivacyPolicy( e.target.checked ) } } /> }
				label={ `Ich habe die Datenschutzerklärung gelesen und ich erkläre mich mit dieser einverstanden. Außerdem habe ich sichergestellt, dass die angemeldeten ${ eventType.match( /jury/i ) ? 'Personen' : 'Kolleginnen bzw. Kollegen' } über deren Inhalte informiert sind.` } />
		</Box>
    )
}

/**
 * Define the (expected) prop-types of this component.
 *
 * @typedef {Object} AcceptPrivacyPolicy.propTypes
 * @property {boolean} shallDispatch – Decide whether the component state should be dispatched to the store.
 */
AcceptPrivacyPolicy.propTypes = {
	shallDispatch: PropTypes.bool.isRequired
}

export default AcceptPrivacyPolicy
