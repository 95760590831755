/**
 * ContactInformation.jsx
 *
 * @file This component creates a part of the registration form,
 * so the user is able to fill in his/her contact data.
 * @author Robin Walter <hello@robinwalter.me>
 */

import _ from 'lodash'
import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { useDispatch, useSelector } from 'react-redux'
import validator from 'validator'

// internal imports
import {
	changeAppellation,
	changeFirstName,
	changeMail,
	changeName,
	selectEventRegistrationAppellation,
	selectEventRegistrationFirstName,
	selectEventRegistrationMail,
	selectEventRegistrationName
} from '../../../state'

/**
 * This component creates the first part of the registration form,
 * so the user is able to fill in his/her personal contact data.
 *
 * @param {Object} props The component props.
 * @returns {Node} The rendered form part.
 */
const ContactInformation = ({ shallDispatch }) => {

	// Retrieve the state from the store.
	/** Retrieve the current value for the `appellation` key from the store. */
	const appellationStore = useSelector( selectEventRegistrationAppellation )
	/** Retrieve the current value for the `firstName` key from the store. */
	const firstNameStore = useSelector( selectEventRegistrationFirstName )
	/** Retrieve the current value for the `mail` key from the store. */
	const mailStore = useSelector( selectEventRegistrationMail )
	/** Retrieve the current value for the `name` key from the store. */
	const nameStore = useSelector( selectEventRegistrationName )

	const dispatch = useDispatch()

	// Define the component states.
	/** Define a state holding the chosen appellation. */
	const [ appellation, setAppellation ] = useState( appellationStore )
	/** Define a state holding the first name. */
	const [ firstName, setFirstName ] = useState( firstNameStore )
	/** Define a state holding the mail address. */
	const [ mail, setMail ] = useState( mailStore )
	/** Define a state holding the last name. */
	const [ name, setName ] = useState( nameStore )

	/**
	 * Check if the email isn't empty and an actual e-mail address.
	 */
	const validateEmail = () => {
		if ( !validator.isEmail( mail.value ) || validator.isEmpty( mail.value ) )
			setMail( {
				...mail,
				error: true,
				verified: false
			} )

		else
			setMail( {
				...mail,
				error: false,
				verified: true
			} )
	}

	/**
	 * Check if the names aren't empty and doesn't include any numbers.
	 *
	 * @param {Object} e The triggered event object.
	 */
	const validateName = e => {
		const nameToValidate = e.target.name === 'firstName' ? firstName.value : name.value

		if ( !validator.matches( nameToValidate, /^([^0-9]*)$/ ) || validator.isEmpty( nameToValidate ) ) e.target.name === 'firstName' ?
			setFirstName( {
				error: true,
				value: nameToValidate,
				verified: false
			} )
		:
			setName( {
				error: true,
				value: nameToValidate,
				verified: false
			} )

		else e.target.name === 'firstName' ?
			setFirstName( {
				error: false,
				value: nameToValidate,
				verified: true
			} )
		:
			setName( {
				error: false,
				value: nameToValidate,
				verified: true
			} )
	}

	/** Check if the data should be dispatched to the store and update if necessary. */
	useEffect(
		() => {
			if ( shallDispatch ) {
				if ( !_.isEqual( appellationStore, appellation ) ) dispatch( changeAppellation( appellation ) )
				if ( !_.isEqual( firstNameStore, firstName ) ) dispatch( changeFirstName( firstName ) )
				if ( !_.isEqual( mailStore, mail ) ) dispatch( changeMail( mail ) )
				if ( !_.isEqual( nameStore, name ) ) dispatch( changeName( name ) )
			}
		},
		[ appellation.value, firstName.value, mail.value, name.value, shallDispatch ]
	)

	return (
		<Box>
			<Typography paragraph>Wie können wir Sie erreichen?</Typography>
			<TextField
				error={ appellation.error }
				fullWidth
				helperText={ appellation.error ? 'Bitte wählen Sie eine Angabe!' : null }
				id="registration-appellation"
				label="Anrede"
				name="appellation"
				onChange={ e => {
					setAppellation( {
						...appellation,
						value: e.target.value
					} )
				} }
				select
				value={ appellation.value }>
				<MenuItem value=""><em>Keine Angabe</em></MenuItem>
				<MenuItem value="FRAU">Frau</MenuItem>
				<MenuItem value="HERR">Herr</MenuItem>
				<MenuItem value="FRAU_DR">Frau Dr.</MenuItem>
				<MenuItem value="HERR_DR">Herr Dr.</MenuItem>
				<MenuItem value="FRAU_PROF_DR">Frau Prof. Dr.</MenuItem>
				<MenuItem value="HERR_PROF_DR">Herr Prof. Dr.</MenuItem>
			</TextField>
			<TextField
				error={ firstName.error }
				fullWidth
				helperText={ firstName.error ? 'Bitte geben Sie einen Vornamen ein!' : null }
				id="registration-first-name"
				label="Vorname"
				name="firstName"
				onBlur={ validateName }
				onChange={ e => {
					setFirstName( {
						...firstName,
						value: e.target.value
					} )
				} }
				required
				type="text"
				value={ firstName.value } />
			<TextField
				error={ name.error }
				fullWidth
				helperText={ name.error ? 'Bitte geben Sie einen Namen ein!' : null }
				id="registration-name"
				label="Name"
				name="name"
				onBlur={ validateName }
				onChange={ e => {
					setName( {
						...name,
						value: e.target.value
					} )
				} }
				required
				type="text"
				value={ name.value } />
			<TextField
				error={ mail.error }
				fullWidth
				helperText={ mail.error ? 'Bitte geben Sie eine gültige E-Mail Adresse ein!' : null }
				id="registration-mail"
				label="E-Mail Adresse"
				name="mail"
				onBlur={ validateEmail }
				onChange={ e => {
					setMail( {
						...mail,
						value: e.target.value
					} )
				} }
				required
				type="email"
				value={ mail.value } />
		</Box>
	)
}

/**
 * Define the (expected) prop-types of this component.
 *
 * @typedef {Object} ContactInformation.propTypes
 * @property {boolean} shallDispatch – Decide whether the component state should be dispatched to the store.
 */
ContactInformation.propTypes = {
	shallDispatch: PropTypes.bool.isRequired
}

export default ContactInformation
