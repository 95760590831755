/**
 * transformParticipantType.js
 *
 * @file This file exports a function which transform the participant type to a display-friendly value.
 * @author Robin Walter <hello@robinwalter.me>
 */

/**
 * Transform the type value to user friendly description.
 *
 * @param {string} string The type value.
 * @returns {string} The description of the type.
 */
export default string => {

	switch ( string ) {

		case 'PUPIL':
			return 'Schüler/in Sekundarstufe II'
		case 'PARENT':
			return 'Elternteil'
		case 'TEACHER':
			return 'Lehrer/in'
		case 'FORMER':
			return 'Ehemalige/r'
		default:
			return 'Sonstige'

	}

}
