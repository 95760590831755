/**
 * Overview.jsx
 *
 * @file This component creates a part of the registration form,
 * so the user is able to review his/her data.
 * @author Robin Walter <hello@robinwalter.me>
 */

import Box from '@mui/material/Box'
import { lighten } from '@mui/material/styles'
import React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TextField from '@mui/material/TextField'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import { useSelector } from 'react-redux'
import validator from 'validator'

// internal imports
import {
	selectEventRegistrationAppellation,
	selectEventRegistrationCity,
	selectEventRegistrationEvent,
	selectEventRegistrationFirstName,
	selectEventRegistrationFirstParticipation,
	selectEventRegistrationMail,
	selectEventRegistrationMessage,
	selectEventRegistrationName,
	selectEventRegistrationParticipants,
	selectEventRegistrationPostalCode,
	selectEventRegistrationRegionalNetwork,
	selectEventRegistrationSchool,
	selectEventRegistrationType,
	selectSettingAppellation,
	selectSettingFirstName,
	selectSettingMail,
	selectSettingName,
	selectSettingSchool,
	settingsInitialState
} from '../../../state'
import { transformAppellation, transformParticipantType } from '../../../utils'

/**
 * This component creates the last part of the registration form,
 * so the user is able to review his/her data.
 *
 * @returns {Node} The rendered form part.
 */
const Overview = () => {

	// Retrieve the state from the store.
	/** Retrieve the current value of the `appellation` key from the store. */
	const appellation = useSelector( selectEventRegistrationAppellation )
	/** Retrieve the `seetings.appellation` key from the store. */
	const appellationSetting = useSelector( selectSettingAppellation )
	/** Retrieve the current value of the `city` key from the store. */
	const city = useSelector( selectEventRegistrationCity )
	/** Retrieve the current value of the `event` key from the store. */
	const event = useSelector( selectEventRegistrationEvent )
	/** Retrieve the event `type` from the store. */
	const eventType = useSelector( selectEventRegistrationType )
	/** Retrieve the current value of the `firstName` key from the store. */
	const firstName = useSelector( selectEventRegistrationFirstName )
	/** Retrieve the `settings.firstName` key from the store. */
	const firstNameSetting = useSelector( selectSettingFirstName )
	/** Retrieve the current value of the `firstParticipation` key from the store. */
	const firstParticipation = useSelector( selectEventRegistrationFirstParticipation )
	/** Retrieve the current value of the `mail` key from the store. */
	const mail = useSelector( selectEventRegistrationMail )
	/** Retrieve the `settings.mail` key from the store. */
	const mailSetting = useSelector( selectSettingMail )
	/** Retrieve the current value of the `message` key from the store. */
	const message = useSelector( selectEventRegistrationMessage )
	/** Retrieve the current value of the `name` key from the store. */
	const name = useSelector( selectEventRegistrationName )
	/** Retrieve the `settings.name` key from the store. */
	const nameSetting = useSelector( selectSettingName )
	/** Retrieve the current `participants` from the store. */
	const participants = useSelector( selectEventRegistrationParticipants )
	/** Retrieve the current value of the `postalCode` key from the store. */
	const postalCode = useSelector( selectEventRegistrationPostalCode )
	/** Retrieve the current value of the `regionalNetwork` key from the store. */
	const regionalNetwork = useSelector( selectEventRegistrationRegionalNetwork )
	/** Retrieve the current value of the `school` key from the store. */
	const school = useSelector( selectEventRegistrationSchool )
	/** Retrieve the `settings.school` key from the store. */
	const schoolSetting = useSelector( selectSettingSchool )

	/**
	 * Check if all settings are set.
	 *
	 * @returns True, if all settings were set.
	 */
	 const shallUseSettings = () => {
		if (
			appellationSetting !== settingsInitialState.appellation &&
			mailSetting !== settingsInitialState.mail &&
			firstNameSetting !== settingsInitialState.firstName &&
			nameSetting !== settingsInitialState.name &&
			schoolSetting !== settingsInitialState.school
		)
			return true

		return false
	}

	return (
		<Box sx={{
			border: (theme) => `1px solid ${ lighten( theme.palette.primary.main, 0.6 ) }`,
			borderRadius: (theme) => theme.shape.borderRadius,
			mb: 2,
			p: 2
		}}>
			<Toolbar sx={{ pl: 2, pr: 1 }}>
				<Typography variant="h6" component="div" sx={{ color: 'primary.main', flex: '1 1 100%' }}>Ihre Daten { shallUseSettings() ? '(Übernommen aus den Einstellungen für unsere Website)' : '' }</Typography>
			</Toolbar>
			<TableContainer component="div">
				<Table aria-label="Ihre Daten" sx={{ minWidth: 650 }}>
					<TableHead>
						<TableRow>
							<TableCell>Anrede</TableCell>
							<TableCell>Vorname</TableCell>
							<TableCell>Nachname</TableCell>
							<TableCell>E-Mail Adresse</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						<TableRow>
							<TableCell sx={{ borderBottom: 0 }}>{ transformAppellation( shallUseSettings() ? appellationSetting : appellation.value ) }</TableCell>
							<TableCell sx={{ borderBottom: 0 }}>{ shallUseSettings() ? firstNameSetting : firstName.value }</TableCell>
							<TableCell sx={{ borderBottom: 0 }}>{ shallUseSettings() ? nameSetting : name.value }</TableCell>
							<TableCell sx={{ borderBottom: 0 }}>{ shallUseSettings() ? mailSetting : mail.value }</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</TableContainer>

			<Toolbar sx={{ mt: 2, pl: 2, pr: 1 }}>
				<Typography variant="h6" component="div" sx={{ color: 'primary.main', flex: '1 1 100%' }}>Angaben zu Ihrer Projektschule { shallUseSettings() ? '(Übernommen aus den Einstellungen für unsere Website)' : '' }</Typography>
			</Toolbar>
			<TableContainer component="div">
				<Table aria-label="Angaben zu Ihrer Projektschule" sx={{ minWidth: 650 }}>
					<TableHead>
						<TableRow>
							<TableCell>Name</TableCell>
							<TableCell>Postleitzahl</TableCell>
							<TableCell>Stadt</TableCell>
							<TableCell>Regionalverbund</TableCell>
							{ eventType === 'BASIS' && <TableCell>Erste Teilnahme</TableCell> }
						</TableRow>
					</TableHead>
					<TableBody>
						<TableRow>
							<TableCell sx={{ borderBottom: 0 }}>{ shallUseSettings() ? schoolSetting.label : school.value.label }</TableCell>
							<TableCell sx={{ borderBottom: 0 }}>{ postalCode.value }</TableCell>
							<TableCell sx={{ borderBottom: 0 }}>{ city.value }</TableCell>
							<TableCell sx={{ borderBottom: 0 }}>{ regionalNetwork.value }</TableCell>
							{ eventType === 'BASIS' && <TableCell sx={{ borderBottom: 0 }}>{ firstParticipation.value }</TableCell> }
						</TableRow>
					</TableBody>
				</Table>
			</TableContainer>

			<Toolbar sx={{ mt: 2, pl: 2, pr: 1 }}>
				<Typography variant="h6" component="div" sx={{ color: 'primary.main', flex: '1 1 100%' }}>Die Veranstaltung</Typography>
			</Toolbar>
			<TableContainer component="div">
				<Table aria-label="Die Veranstaltung" sx={{ minWidth: 650 }}>
					<TableHead>
						<TableRow>
							<TableCell>#ID</TableCell>
							<TableCell>Veranstaltungsnummer</TableCell>
							<TableCell>Veranstaltungsort</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						<TableRow>
							<TableCell sx={{ borderBottom: 0 }}>{ event.value.id }</TableCell>
							<TableCell sx={{ borderBottom: 0 }}>{ event.value.eventID }</TableCell>
							{ event.value.venue && <TableCell sx={{ borderBottom: 0 }}>{ event.value.venue.city }</TableCell> }
						</TableRow>
					</TableBody>
				</Table>
			</TableContainer>

			<Toolbar sx={{ mt: 2, pl: 2, pr: 1 }}>
				<Typography variant="h6" component="div" sx={{ color: 'primary.main', flex: '1 1 100%' }}>Ihre Anmeldungen</Typography>
			</Toolbar>
			<TableContainer component="div">
				<Table aria-label="Ihre Anmeldungen" sx={{ minWidth: 650 }}>
					<TableHead>
						<TableRow>
							<TableCell>Anrede</TableCell>
							<TableCell>Vorname</TableCell>
							<TableCell>Nachname</TableCell>
							<TableCell>E-Mail Adresse</TableCell>
							{ eventType === 'JURY' && <TableCell>Personengruppe</TableCell> }
						</TableRow>
					</TableHead>
					<TableBody>
						{ participants.map( ( participant, i ) => (
							<TableRow key={ participant.mail.value }>
								<TableCell sx={{ borderBottom: i === ( participants.length - 1 ) && 0 }}>{ transformAppellation( participant.appellation ) }</TableCell>
								<TableCell sx={{ borderBottom: i === ( participants.length - 1 ) && 0 }}>{ participant.firstName.value }</TableCell>
								<TableCell sx={{ borderBottom: i === ( participants.length - 1 ) && 0 }}>{ participant.name.value }</TableCell>
								<TableCell sx={{ borderBottom: i === ( participants.length - 1 ) && 0 }}>{ participant.mail.value }</TableCell>
								{ eventType === 'JURY' && <TableCell sx={{ borderBottom: i === ( participants.length - 1 ) && 0 }}>{ transformParticipantType( participant.type.value ) }</TableCell> }
							</TableRow>
						) ) }
					</TableBody>
				</Table>
			</TableContainer>

			{ !validator.isEmpty( message ) && (
				<>
					<Toolbar sx={{ mt: 2, pl: 2, pr: 1 }}>
						<Typography variant="h6" component="div" sx={{ color: 'primary.main', flex: '1 1 100%' }}>Mitteilungen zu Ihrer Anmeldung</Typography>
					</Toolbar>
					<TextField
						disabled
						fullWidth
						multiline
						sx={{ pl: 2, pr: 1 }}
						type="text"
						value={ message } />
				</>
			) }
		</Box>
	)
}

export default Overview
