/**
 * Participant.jsx
 *
 * @file This component creates the single `Participant` instances of the
 * `Participants` part from the `RegistrationForm`.
 * @author Robin Walter <hello@robinwalter.me>
 */

import _ from 'lodash'
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormLabel from '@mui/material/FormLabel'
import MenuItem from '@mui/material/MenuItem'
import PropTypes from 'prop-types'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import React, { useEffect, useState } from 'react'
import Switch from '@mui/material/Switch'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { useDispatch, useSelector } from 'react-redux'
import validator from 'validator'

// internal imports
import {
	addParticipant,
	changeParticipant,
	selectEventRegistrationParticipants,
	selectEventRegistrationType
} from '../../../state'

/**
 * Render the single participants and handle their input.
 *
 * @param {Object} props The component props.
 * @returns {Node} The rendered form part.
 */
const Participant = ( { index, shallDispatch } ) => {

	// Retrieve the state from the store.
	/** Retrieve the event `type` from the store. */
	const eventType = useSelector( selectEventRegistrationType )
	/** Retrieve the current `participants` from the store. */
	const participants = useSelector( selectEventRegistrationParticipants )

	const dispatch = useDispatch()

	// Define the component states.
	/** Define a state holding the `acceptedPrivacyPolicy` value. */
	const [ acceptedPrivacyPolicy, toggleAcceptedPrivacyPolicy ] = useState(
		typeof participants[ index ] !== 'undefined' ?
			participants[ index ].acceptedPrivacyPolicy
		:
			false
	)
	/** Define a state holding the `appellation` value. */
	const [ appellation, setAppellation ] = useState(
		typeof participants[ index ] !== 'undefined' ?
			participants[ index ].appellation
		:
			''
	)
	/** Define a state holding the `firstName` value. */
	const [ firstName, setFirstName ] = useState(
		typeof participants[ index ] !== 'undefined' ?
			participants[ index ].firstName
		:
			{
				error: false,
				value: '',
				verified: false
			}
	)
	/** Define a state holding the `mail` value. */
	const [ mail, setMail ] = useState(
		typeof participants[ index ] !== 'undefined' ?
			participants[ index ].mail
		:
			{
				error: false,
				value: '',
				verified: false
			}
	)
	/** Define a state holding the `name` value. */
	const [ name, setName ] = useState(
		typeof participants[ index ] !== 'undefined' ?
			participants[ index ].name
		:
			{
				error: false,
				value: '',
				verified: false
			}
	)
	/** Define a state holding the `type` value. */
	const [ type, setType ] = useState(
		typeof participants[ index ] !== 'undefined' ?
			participants[ index ].type
		:
			{
				error: false,
				value: 'TEACHER',
				verified: true
			}
	)

	/**
	 * Check if the names aren't empty and doesn't include any numbers.
	 *
	 * @param {Object} e The triggered event object.
	 */
	const validateName = e => {
		const nameToValidate = e.target.name === 'participants[].firstName' ? firstName.value : name.value

		if ( !validator.matches( nameToValidate, /^([^0-9]*)$/ ) || validator.isEmpty( nameToValidate ) ) e.target.name === 'participants[].firstName' ?
			setFirstName( {
				error: true,
				value: nameToValidate,
				verified: false
			} )
		:
			setName( {
				error: true,
				value: nameToValidate,
				verified: false
			} )

		else e.target.name === 'participants[].firstName' ?
			setFirstName( {
				error: false,
				value: nameToValidate,
				verified: true
			} )
		:
			setName( {
				error: false,
				value: nameToValidate,
				verified: true
			} )
	}

	/** Check if the data should be dispatched to the store or update if necessary. */
	useEffect(
		() => {
			if ( shallDispatch ) {
				const newParticipant = {
					acceptedPrivacyPolicy,
					appellation,
					firstName,
					mail,
					name,
					type
				}
				if ( typeof participants[ index ] === 'undefined' ) dispatch( addParticipant( newParticipant ) )
				else if ( !_.isEqual( participants[ index ], newParticipant ) )
					dispatch(
						changeParticipant( {
							index,
							participant: newParticipant
						} )
					)
			}
		},
		[
			acceptedPrivacyPolicy,
			appellation,
			firstName.error,
			firstName.value,
			firstName.verified,
			mail.error,
			mail.value,
			mail.verified,
			name.error,
			name.value,
			name.verified,
			type.error,
			type.value,
			type.verified,
			shallDispatch
		]
	)

	return (
        <Box>
			<Typography gutterBottom sx={{ mt: 1 }}>{ `${ index + 1 }. ${ eventType.match( /jury/i ) ? 'Person' : 'Kollegin bzw. Kollege' }` }</Typography>
			{ eventType.match( /jury/i ) &&
				<FormControl
					component="fieldset"
					error={ type.error }
					fullWidth>
					<FormLabel component="legend">Personengruppe</FormLabel>
					<RadioGroup
						name="participants[].type"
						onChange={ e => {
							if ( validator.isEmpty( type.value ) || !validator.isIn( type.value, [ 'FORMER', 'OTHER', 'PARENT', 'PUPIL', 'TEACHER' ] ) )
								setType( {
									...type,
									error: true,
									value: e.target.value,
									verified: false
								} )
							else
								setType( {
									...type,
									error: false,
									value: e.target.value,
									verified: true
								} )
						} }
						row
						value={ type.value }>
						<FormControlLabel
							control={ <Radio /> }
							label="Schüler/in Sekundarstufe II"
							value="PUPIL" />
						<FormControlLabel
							control={ <Radio /> }
							label="Elternteil"
							value="PARENT" />
						<FormControlLabel
							control={ <Radio /> }
							label="Lehrer/in"
							value="TEACHER" />
						<FormControlLabel
							control={ <Radio /> }
							label="Ehemalige/r"
							value="FORMER" />
						<FormControlLabel
							control={ <Radio /> }
							label="Sonstige"
							value="OTHER" />
					</RadioGroup>
				</FormControl>
			}
			<TextField
				fullWidth
				id={ `registration-participants-${ index }-appellation` }
				label="Anrede"
				name="participants[].appellation"
				onChange={ e => { setAppellation( e.target.value ) } }
				select
				value={ appellation }>
				<MenuItem value=""><em>Keine Angabe</em></MenuItem>
				<MenuItem value="FRAU">Frau</MenuItem>
				<MenuItem value="HERR">Herr</MenuItem>
				<MenuItem value="FRAU_DR">Frau Dr.</MenuItem>
				<MenuItem value="HERR_DR">Herr Dr.</MenuItem>
				<MenuItem value="FRAU_PROF_DR">Frau Prof. Dr.</MenuItem>
				<MenuItem value="HERR_PROF_DR">Herr Prof. Dr.</MenuItem>
			</TextField>
			<TextField
				error={ firstName.error }
				fullWidth
				helperText={ firstName.error ? 'Bitte geben Sie einen Vornamen ein!' : null }
				id={ `registration-participants-${ index }-first-name` }
				label="Vorname"
				name="participants[].firstName"
				onBlur={ validateName }
				onChange={ e => { setFirstName( { ...firstName, value: e.target.value } ) } }
				required
				type="text"
				value={ firstName.value } />
			<TextField
				error={ name.error }
				fullWidth
				helperText={ name.error ? 'Bitte geben Sie einen Namen ein!' : null }
				id={ `registration-participants-${ index }-name` }
				label="Name"
				name="participants[].name"
				onBlur={ validateName }
				onChange={ e => { setName( { ...name, value: e.target.value } ) } }
				required
				type="text"
				value={ name.value } />
			<TextField
				error={ mail.error }
				fullWidth
				helperText={ mail.error ? 'Bitte geben Sie eine gültige E-Mail Adresse ein!' : null }
				id={ `registration-participants-${ index }-mail` }
				label="E-Mail Adresse"
				name="participants[].mail"
				onBlur={ () => {
					if ( !validator.isEmail( mail.value ) || validator.isEmpty( mail.value ) )
						setMail( {
							...mail,
							error: true,
							verified: false
						} )
					else
						setMail( {
							...mail,
							error: false,
							verified: true
						} )
				} }
				onChange={ e => { setMail( { ...mail, value: e.target.value } ) } }
				required
				type="email"
				value={ mail.value } />
			<FormControlLabel
				control={
					<Switch
						checked={ Boolean( acceptedPrivacyPolicy ) }
						color="primary"
						inputProps={ { id: `registration-participants-${ index }-accepted-privacy-policy`, name: 'participants[].acceptedPrivacyPolicy' } }
						onChange={ e => { toggleAcceptedPrivacyPolicy( e.target.checked ) } } /> }
				label={ `Mir liegt das Einverständnis der ${ eventType.match( /jury/i ) ? 'Person' : 'Kollegin bzw. des Kollegen' } zur Anmeldung und Verarbeitung persönlicher Daten vor.` } />
		</Box>
    )
}

/**
 * Define the (expected) prop-types of this component.
 *
 * @typedef {Object} Participant.propTypes
 * @property {number}  index         – The index of the participant.
 * @property {boolean} shallDispatch – Decide whether the component state should be dispatched to the store.
 */
Participant.propTypes = {
	index: PropTypes.number.isRequired,
	shallDispatch: PropTypes.bool.isRequired
}

export default Participant
