/**
 * School.jsx
 *
 * @file This component creates a part of the registration form,
 * so the user is able to fill in the information about his/her school.
 * @author Robin Walter <hello@robinwalter.me>
 */

import _ from 'lodash'
import Autocomplete from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import FormControlLabel from '@mui/material/FormControlLabel'
import log from 'loglevel'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import Switch from '@mui/material/Switch'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { useDispatch, useSelector } from 'react-redux'
import validator from 'validator'

// internal imports
import {
	changeCity,
	changeFirstParticipation,
	changePostalCode,
	changeRegionalNetwork,
	changeSchool,
	selectEventRegistrationCity,
	selectEventRegistrationFirstParticipation,
	selectEventRegistrationPostalCode,
	selectEventRegistrationRegionalNetwork,
	selectEventRegistrationSchool,
	selectEventRegistrationSchools,
	selectEventRegistrationType
} from '../../../state'

/**
 * This component creates the second part of the registration form,
 * so the user is able to fill in the information about his/her school.
 *
 * @param {Object} props The component props.
 * @returns {Node} The rendered form part.
 */
const School = ({ shallDispatch }) => {

	const defaultSchool = {
		city: '',
		firstParticipation: '',
		id: '0',
		key: '0',
		label: 'Bitte wählen Sie eine Schule',
		name: '',
		postalCode: '',
		regionalNetwork: '',
		__typename: 'School'
	}

	// Retrieve the state from the store.
	/** Retrieve the current value for the `city` key from the store. */
	const cityStore = useSelector( selectEventRegistrationCity )
	/** Retrieve the event `type` from the store. */
	const eventType = useSelector( selectEventRegistrationType )
	/** Retrieve the current value for the `firstParticipation` key from the store. */
	const firstParticipationStore = useSelector( selectEventRegistrationFirstParticipation )
	/** Retrieve the current value for the `postalCode` key from the store. */
	const postalCodeStore = useSelector( selectEventRegistrationPostalCode )
	/** Retrieve the current value for the `regionalNetwork` key from the store. */
	const regionalNetworkStore = useSelector( selectEventRegistrationRegionalNetwork )
	/** Retrieve the current value for the `school` key from the store. */
	const schoolStore = useSelector( selectEventRegistrationSchool )
	/** Retrieve the fetched `schools` from the store. */
	const schools = useSelector( selectEventRegistrationSchools )

	// Add the default school to the schools array, because otherwise the default value
	// can't be found and the website will freeze
	const autoCompleteSchools = [ ...schools ]
	if ( !_.some(autoCompleteSchools, defaultSchool) ) {
		autoCompleteSchools.unshift( defaultSchool )
	}

	const dispatch = useDispatch()

	// Define the component states.
	/** Define a state holding the chosen city. */
	const [ city, setCity ] = useState( cityStore )
	/** Define a state holding the first participation of the school. */
	const [ firstParticipation, setFirstParticipation ] = useState( firstParticipationStore )
	/** Define a state holding the information, if the user is working at a project school or not. */
	const [ noSchool, setNoSchool ] = useState( false )
	/** Define a state holding the postal code of the schools address. */
	const [ postalCode, setPostalCode ] = useState( postalCodeStore )
	/** Define a state holding the regional network the school belongs to. */
	const [ regionalNetwork, setRegionalNetwork ] = useState( regionalNetworkStore )
	/** Define a state holding the school itself. */
	const [ school, setSchool ] = useState( schoolStore )

	/**
	 * Callback to handle a changed schools.
	 *
	 * @param {Object} event The triggered event object.
	 * @param {Object} newValue The new value for the `school`.
	 * @param {string} reason A reason why this callback was invoked.
	 */
	const handleSchoolChanged = ( event, newValue, reason ) => {
		let newCity = '', newFirstParticipation = '', newPostalCode = '',
			newRegionalNetwork = '', newSchool = defaultSchool

		log.debug(reason)
		log.debug(newValue)
		switch ( reason ) {
			case 'selectOption':
				newCity               = newValue.city
				if ( eventType.match( /basis/i ) )
					newFirstParticipation = newValue.firstParticipation
				newPostalCode         = newValue.postalCode
				newRegionalNetwork    = newValue.regionalNetwork
				newSchool             = newValue
				break
		}

		setCity( {
			...city,
			value: newCity,
			verified: true
		} )
		if ( eventType.match( /basis/i ) )
			setFirstParticipation( {
				...firstParticipation,
				value: newFirstParticipation,
				verified: true
			} )
		setPostalCode( {
			...postalCode,
			value: newPostalCode,
			verified: true
		} )
		setRegionalNetwork( {
			...regionalNetwork,
			value: newRegionalNetwork,
			verified: true
		} )
		setSchool( {
			...school,
			value: newSchool
		} )
	}

	/**
	 * Check if the value for the `city` key isn't empty.
	 */
	const validateCity = () => {
		if ( validator.isEmpty( city.value ) )
			setCity( {
				...city,
				error: true,
				verified: false
			} )

		else
			setCity( {
				...city,
				error: false,
				verified: true
			} )
	}

	/**
	 * Check if the value for the `firstParticipation` key isn't empty.
	 */
	const validateFirstParticipation = () => {
		if ( eventType.match( /basis/i ) ) {
			if ( validator.isEmpty( firstParticipation.value.toString() ) )
				setFirstParticipation( {
					...firstParticipation,
					error: true,
					verified: false
				} )

			else
				setFirstParticipation( {
					...firstParticipation,
					error: false,
					verified: true
				} )
		}
	}

	/**
	 *  Check if the value for the `postalCode` key isn't empty and correct german postal code.
	 */
	const validatePostalCode = () => {
		if ( !validator.isPostalCode( postalCode.value, 'DE' ) || validator.isEmpty( postalCode.value ) )
			setPostalCode( {
				...postalCode,
				error: true,
				verified: false
			} )

		else
			setPostalCode( {
				...postalCode,
				error: false,
				verified: true
			} )
	}

	/**
	 *  Check if the value for the `regionalNetwork` key isn't empty.
	 */
	const validateRegionalNetwork = () => {
		if ( validator.isEmpty( regionalNetwork.value.toString() ) )
			setRegionalNetwork( {
				...regionalNetwork,
				error: true,
				verified: false
			} )

		else
			setRegionalNetwork( {
				...regionalNetwork,
				error: false,
				verified: true
			} )
	}

	/**
	 *  Check if the value for the `school` key isn't empty and in the `schools` array.
	 */
	const validateSchool = () => {
		if (
			!noSchool &&
			(
				!validator.isIn( school.value.name, schools.map( school => school.name ) )
				|| !_.some( schools, school.value )
				|| _.isEqual( school.value, defaultSchool )
			)
		)
			setSchool( {
				...school,
				error: true,
				verified: false
			} )

		else
			setSchool( {
				...school,
				error: false,
				verified: true
			} )
	}

	/** Check if the data should be dispatched to the store, validate the `school` and update the store if necessary. */
	useEffect(
		() => {
			if ( shallDispatch ) {
				validateSchool()
				if ( school.verified ) {
					if ( !_.isEqual( cityStore, city ) ) dispatch( changeCity( city ) )
					if ( eventType.match( /basis/i ) ) {
						if ( !_.isEqual( firstParticipationStore, firstParticipation ) ) dispatch( changeFirstParticipation( firstParticipation ) )
					}
					if ( !_.isEqual( postalCodeStore, postalCode ) ) dispatch( changePostalCode( postalCode ) )
					if ( !_.isEqual( regionalNetworkStore, regionalNetwork ) ) dispatch( changeRegionalNetwork( regionalNetwork ) )
					if ( !_.isEqual( schoolStore, school ) ) dispatch( changeSchool( school ) )
				}
			}
		},
		[
			school.value.id,
			school.verified,
			shallDispatch
		]
	)

	return (
        <Box>
			{ eventType.match( /sprachlerngruppe/i ) &&
			<FormControlLabel
				control={
					<Switch
						checked={ noSchool }
						color="primary"
						inputProps={ { id: 'registration-no-school', name: 'noSchool' } }
						onChange={ e => { setNoSchool( e.target.checked ) } } /> }
				label="Ich unterrichte nicht an einer Projektschule von Jugend debattiert." />
			}
			<Typography paragraph>Wo sind Sie als Schulkoordinator/in für Jugend debattiert tätig?</Typography>
			<Autocomplete
				autoComplete={ false }
				clearOnBlur
				defaultValue={ defaultSchool }
				disabled={ noSchool }
				fullWidth
				getOptionLabel={ option => option.label }
				isOptionEqualToValue={ (option, value) => {
					return _.isEqual( option, value )
				} }
				groupBy={ option => option.regionalNetwork }
				handleHomeEndKeys
				id="registration-school"
				name="school"
				onChange={ handleSchoolChanged }
				options={ autoCompleteSchools }
				renderInput={ params => <TextField error={ school.error } helperText={ school.error ? 'Bitte geben Sie einen gültigen Schulnamen ein!' : null } label="Schulname" required { ...params } /> }
				selectOnFocus
				value={ school.value } />
			<TextField
				autoComplete="address-level2"
				disabled
				error={ city.error }
				fullWidth
				helperText={ city.error ? 'Bitte geben Sie einen gültigen Ort ein!' : null }
				id="registration-city"
				label="Ort"
				name="city"
				onBlur={ validateCity }
				onChange={ e => {
					setCity( {
						...city,
						value: e.target.value
					} )
				} }
				required
				type="text"
				value={ city.value } />
			<TextField
				autoComplete="postal-code"
				disabled
				error={ postalCode.error }
				fullWidth
				helperText={ postalCode.error ? 'Bitte geben Sie eine gültige Postleitzahl ein!' : null }
				id="registration-postalCode"
				label="Postleitzahl"
				name="postalCode"
				onBlur={ validatePostalCode }
				onChange={ e => {
					setPostalCode( {
						...postalCode,
						value: e.target.value
					} )
				} }
				required
				type="text"
				value={ postalCode.value } />
			{ eventType.match( /basis/i ) &&
				<TextField
					disabled
					error={ firstParticipation.error }
					fullWidth
					helperText={ firstParticipation.error ? 'Bitte wählen Sie eine Schule aus!' : null }
					id="registration-firstParticipation"
					label="Seit wann ist Ihre Schule Projektschule von Jugend debattiert?"
					name="firstParticipation"
					onBlur={ validateFirstParticipation }
					onChange={ e => {
						setFirstParticipation( {
							...firstParticipation,
							value: e.target.value
						} )
					} }
					required
					type="text"
					value={ firstParticipation.value } />
			}
			<TextField
				disabled
				error={ regionalNetwork.error }
				fullWidth
				helperText={ regionalNetwork.error ? 'Bitte wählen Sie eine Schule aus!' : null }
				id="registration-regionalNetwork"
				label="Zu welchem Regionalverbund gehört Ihre Schule?"
				name="regionalNetwork"
				onBlur={ validateRegionalNetwork }
				onChange={ e => {
					setRegionalNetwork( {
						...regionalNetwork,
						value: e.target.value
					} )
				} }
				required
				type="text"
				value={ regionalNetwork.value } />
		</Box>
    )
}

/**
 * Define the (expected) prop-types of this component.
 *
 * @typedef {Object} School.propTypes
 * @property {boolean} shallDispatch – Decide whether the component state should be dispatched to the store.
 */
School.propTypes = {
	shallDispatch: PropTypes.bool.isRequired
}

export default School
