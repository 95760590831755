/**
 * RegistrationForm.jsx
 *
 * @file This component creates the actual registration form.
 * @author Robin Walter <hello@robinwalter.me>
 */

import _ from 'lodash'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import { gql, useMutation, useQuery } from '@apollo/client'
import log from 'loglevel'
import { navigate } from 'gatsby'
import NProgress from 'nprogress'
import React, { useEffect, useState } from 'react'
import Step from '@mui/material/Step'
import StepContent from '@mui/material/StepContent'
import StepLabel from '@mui/material/StepLabel'
import Stepper from '@mui/material/Stepper'
import Typography from '@mui/material/Typography'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from '@reach/router'
import validator from 'validator'

// internal imports
import {
	AcceptPrivacyPolicy,
	ContactInformation,
	Message,
	Overview,
	Participants,
	School,
	ThankYou
} from '../'
import { Link } from '../../Link'
import {
	resetEventRegistration,
	selectEventRegistrationAcceptedPrivacyPolicy,
	selectEventRegistrationAppellation,
	selectEventRegistrationEvent,
	selectEventRegistrationEvents,
	selectEventRegistrationFirstName,
	selectEventRegistrationMail,
	selectEventRegistrationMessage,
	selectEventRegistrationName,
	selectEventRegistrationParticipants,
	selectEventRegistrationSchool,
	selectEventRegistrationSchools,
	selectEventRegistrationType,
	selectSettingAppellation,
	selectSettingFirstName,
	selectSettingMail,
	selectSettingName,
	selectSettingSchool,
	settingsInitialState,
	storeEvents,
	storeSchools
} from '../../../state'
import routes from '../../../data/routes.json'

/** Mutation to register the participation. */
const ADD_PARTICIPATION = gql`
	mutation addParticipation(
		$appellation: Appellation
		$comment: String
		$email: Email!
		$eventID: ID!
		$firstName: String!
		$lastName: String!
		$participants: CreateParticipantsRelation!
		$schoolID: ID
	) {
		createParticipation( input: {
			appellation: $appellation
			comment: $comment
			email: $email
			event: {
				connect: $eventID
			}
			firstName: $firstName
			lastName: $lastName
			participants: $participants
			school: {
				connect: $schoolID
			}
		} ) {
			id
		}
	}
`

/** Query to get all events and schools from the server. */
const GET_EVENTS_SCHOOLS = gql`
	query GetEventsSchools( $type: String ) {
		allEvents(
			sort: [
				{ column: "event_id" order: ASC }
			]
			type: $type
		) {
			eventID
			id
			venue {
				city
				id
			}
		}
    	allSchools(
			sort: [
				{ column: "regional_network" order: ASC },
				{ column: "city" order: ASC },
				{ column: "name" order: ASC }
        	]
		) {
        	city
        	firstParticipation
        	id
        	name
        	postalCode
        	regionalNetwork
    	}
	}
`

/**
 * This component renders the actual registration form.
 *
 * @param {Object} props The component props.
 * @returns {Node} The rendered form.
 */
const RegistrationForm = ( { maxParticipants } ) => {

	/** Get the URL params from @reach/router */
	const params = useParams()

	const logGraphQL = log.getLogger('graphql')

	// Retrieve the state from the store.
	/** Retrieve the `acceptedPrivacyPolicy` key from the store. */
	const acceptedPrivacyPolicy = useSelector( selectEventRegistrationAcceptedPrivacyPolicy )
	/** Retrieve the `appellation` key from the store. */
	const appellation = useSelector( selectEventRegistrationAppellation )
	/** Retrieve the `seetings.appellation` key from the store. */
	const appellationSetting = useSelector( selectSettingAppellation )
	/** Retrieve the `event` key from the store. */
	const event = useSelector( selectEventRegistrationEvent )
	/** Retrieve the fetched `events` from the store. */
	const eventsStore = useSelector( selectEventRegistrationEvents )
	/** Retrieve the event `type` from the store. */
	const eventType = useSelector( selectEventRegistrationType )
	/** Retrieve the `firstName` key from the store. */
	const firstName = useSelector( selectEventRegistrationFirstName )
	/** Retrieve the `settings.firstName` key from the store. */
	const firstNameSetting = useSelector( selectSettingFirstName )
	/** Retrieve the `mail` key from the store. */
	const mail = useSelector( selectEventRegistrationMail )
	/** Retrieve the `settings.mail` key from the store. */
	const mailSetting = useSelector( selectSettingMail )
	/** Retrieve the `message` key from the store. */
	const message = useSelector( selectEventRegistrationMessage )
	/** Retrieve the `name` key from the store. */
	const name = useSelector( selectEventRegistrationName )
	/** Retrieve the `settings.name` key from the store. */
	const nameSetting = useSelector( selectSettingName )
	/** Retrieve the `participants` array from the store. */
	const participants = useSelector( selectEventRegistrationParticipants )
	/** Retrieve the `school` key from the store. */
	const school = useSelector( selectEventRegistrationSchool )
	/** Retrieve the `settings.school` key from the store. */
	const schoolSetting = useSelector( selectSettingSchool )
	/** Retrieve the fetched `schools` from the store. */
	const schoolsStore = useSelector( selectEventRegistrationSchools )

	const dispatch = useDispatch()

	/**
	 * Check if all settings are set.
	 *
	 * @returns True, if all settings were set.
	 */
	const shallUseSettings = () => {
		if (
			appellationSetting !== settingsInitialState.appellation &&
			mailSetting !== settingsInitialState.mail &&
			firstNameSetting !== settingsInitialState.firstName &&
			nameSetting !== settingsInitialState.name &&
			schoolSetting !== settingsInitialState.school
		)
			return true

		return false
	}

	// Define the component states.
	/** Define a state holding the active step. */
	const [ activeStep, setActiveStep ] = useState( shallUseSettings() ? 2 : 0 )
	/** Define a state disabling the submit button. */
	const [ disableSubmit, setDisableSubmit ] = useState( true )
	/** Define a state to inform the `AcceptPrivacyPolicy` to dispatch the data to the store. */
	const [ dispatchAcceptPrivacyPolicy, setDispatchAcceptPrivacyPolicy ] = useState( false )
	/** Define a state to inform the `ContactInformation` to dispatch the data to the store. */
	const [ dispatchContactInformation, setDispatchContactInformation ] = useState( false )
	/** Define a state to inform the `Message` to dispatch the data to the store. */
	const [ dispatchMessage, setDispatchMessage ] = useState( false )
	/** Define a state to inform the `Participants` to dispatch the data to the store. */
	const [ dispatchParticipants, setDispatchParticipants ] = useState( false )
	/** Define a state to inform the `School` to dispatch the data to the store. */
	const [ dispatchSchool, setDispatchSchool ] = useState( false )
	/** Define a state storing the events. */
	const [ events, setEvents ] = useState( eventsStore )
	/** Define a state storing the schools. */
	const [ schools, setSchools ] = useState( schoolsStore )
	/** Define a state holding the status of the submission. */
	const [ submitError, setSubmitError ] = useState( null )
	/** Define a state holding the information, if the form was submitted by the user. */
	const [ submitted, setSubmitted ] = useState( false )

	/** Fetch all events and schools from the GraphQL Server. */
	const { error: queryError, loading: queryLoading } = useQuery( GET_EVENTS_SCHOOLS, {
		displayName: 'GetEventsSchoolsQuery',
		onCompleted: data => {
			if ( data && data.allEvents ) {
				setEvents(
					data.allEvents.map( event => ( {
						...event,
						label: event.venue ? `${ event.eventID } (${ event.venue.city })` : `${ event.eventID }`,
						venue: event.venue ? event.venue : {
							city: '',
							id: '',
							__typename: 'Venue'
						}
					} ) )
				)
			}
			if ( data && data.allSchools ) {
				setSchools(
					data.allSchools.map( school => ( {
						...school,
						key: school.id,
						label: `${ school.name } (${ school.city })`
					} ) )
				)
			}
		},
		ssr: false,
		variables: {
			type: eventType
		}
	} )

	/** Create a new `Participation` on the server. */
	const [ addParticipation, { loading: mutationLoading } ] = useMutation( ADD_PARTICIPATION, {
		onCompleted: () => {
			setSubmitError( null )
			setSubmitted( true )
		},
		onError: error => {
			logGraphQL.error('An error occured while registrating the participation', error)
			setSubmitError( error )
			setSubmitted( true )
		},
		variables: {
			appellation: shallUseSettings() ? appellationSetting : appellation.value,
			comment: message,
			email: shallUseSettings() ? mailSetting : mail.value,
			eventID: event.value.id,
			firstName: shallUseSettings() ? firstNameSetting : firstName.value,
			lastName: shallUseSettings() ? nameSetting : name.value,
			participants: {
				create: participants.map( participant => ( {
					appellation: participant.appellation,
					email: participant.mail.value,
					firstName: participant.firstName.value,
					lastName: participant.name.value,
					status: 'ANGEMELDET',
					type: participant.type.value
				} ) )
			},
			schoolID: ( eventType.match( /sprachlerngruppe/i ) && school.value.id === '0' ) ? null : ( shallUseSettings() ? schoolSetting.id : school.value.id )
		}
	} )

	/** Define the different steps of the `RegistrationForm`. */
	const steps = [
		`Ihre Daten ${ shallUseSettings() ? '(Übernommen aus den Einstellungen für unsere Website)' : '' }`,
		`Angaben zu Ihrer Projektschule ${ shallUseSettings() ? '(Übernommen aus den Einstellungen für unsere Website)' : '' }`,
		`Ihre Anmeldungen`,
		`Mitteilungen zu Ihrer Anmeldung`,
		`Bestätigung der Datenschutzerklärung`
	]

	/**
	 * Get the correct part of the form.
	 *
	 * @param {number} step The step to retrieve.
	 * @returns {Node} The rendered part of the form.
	 */
	const getStepContent = step => {
		switch ( step ) {
			case 0:
				return <ContactInformation shallDispatch={ dispatchContactInformation } />
			case 1:
				return <School shallDispatch={ dispatchSchool } />
			case 2:
				return <Participants eventId={ params.eventId ? params.eventId.replace(/\/2$/) : null } maxParticipants={ maxParticipants } shallDispatch={ dispatchParticipants } />
			case 3:
				return <Message shallDispatch={ dispatchMessage } />
			case 4:
				return <AcceptPrivacyPolicy shallDispatch={ dispatchAcceptPrivacyPolicy } />
			default:
				return <Typography>Unbekannter Schritt</Typography>
		}
	}

	/**
	 * Validate the whole `RegistrationForm` to decide, if the user is allowed
	 * to send his/her participation to the server or if he/she has to adjust
	 * the input.
	 *
	 * @returns {any} Fails if any value isn't verified.
	 */
	const validateForm = () => {
		if ( !shallUseSettings() ) {
			if ( !appellation.verified || !firstName.verified || !name.verified || !mail.verified ) {
				setDisableSubmit( true )
				return
			}

			if ( !school.verified ) {
				setDisableSubmit( true )
				return
			}
		}

		if ( !event.verified ) {
			setDisableSubmit( true )
			return
		}

		for ( let i = 0; i < participants.length; i++ ) {
			if (
				(
					!validator.isBoolean( participants[ i ].acceptedPrivacyPolicy.toString() ) ||
					!participants[ i ].acceptedPrivacyPolicy ||
					validator.isEmpty( participants[ i ].acceptedPrivacyPolicy.toString() )
				) ||
				!participants[ i ].firstName.verified ||
				!participants[ i ].name.verified ||
				!participants[ i ].mail.verified ||
				( eventType === 'JURY' && !participants[ i ].type.verified )
			) {
				setDisableSubmit( true )
				return
			}
		}

		if (
			!validator.isBoolean( acceptedPrivacyPolicy.toString() ) ||
			!acceptedPrivacyPolicy ||
			validator.isEmpty( acceptedPrivacyPolicy.toString() )
		) {
			setDisableSubmit( true )
			return
		}

		setDisableSubmit( false )
	}

	/** Inform the sub components to dispatch their data to the store, if necessary store/update the fetched events & schools and reset the form. */
	useEffect( () => {
		switch ( activeStep ) {
			case 0:
				setDispatchContactInformation( false )
				break
			case 1:
				setDispatchContactInformation( true )
				setDispatchSchool( false )
				break
			case 2:
				setDispatchSchool( true )
				setDispatchParticipants( false )
				break
			case 3:
				setDispatchParticipants( true )
				setDispatchMessage( false )
				break
			case 4:
				setDispatchMessage( true )
				setDispatchAcceptPrivacyPolicy( true )
				break
			default:
				setDispatchContactInformation( false )
				setDispatchSchool( false )
				setDispatchParticipants( false )
				setDispatchMessage( false )
				setDispatchAcceptPrivacyPolicy( false )
		}
		if ( !_.isEqual( eventsStore, events ) ) dispatch( storeEvents( events ) )
		if ( !_.isEqual( schoolsStore, schools ) ) dispatch( storeSchools( schools ) )
		if ( submitted ) dispatch( resetEventRegistration() )
	}, [ activeStep, dispatch, events, schools, eventsStore, schoolsStore, submitted ] )

	if ( queryLoading ) {
		NProgress.start()

		return (
			<Box
				sx={{
					alignItems: 'center',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					height: '100%',
					mb: 2,
					textAlign: 'center',
					width: '100%',
				}}>
				<CircularProgress
					size={ 50 }
					sx={{ color: 'secondary.main' }} />
			</Box>
		)
	}
	else if ( mutationLoading )
		NProgress.start()

	else NProgress.done()

	if ( queryError )
		return (
			<Box
				sx={{
					alignItems: 'center',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					height: '100%',
					mb: 2,
					textAlign: 'center',
					width: '100%',
				}}>
				<Typography>Es trat ein unerwarteter Fehler auf.</Typography>
			</Box>
		)

	if ( events.length === 0 )
		return (
			<Box
				sx={{
					alignItems: 'center',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					height: '100%',
					mb: 2,
					textAlign: 'center',
					width: '100%',
				}}>
				<Typography>
					Zur Zeit gibt es keine Veranstaltungen, zu denen Sie sich oder Ihre Kolleginnen bzw. Kollegen anmelden können.
				</Typography>
			</Box>
		)

	return (
		<>
			<Typography paragraph sx={{ display: submitted && 'none' }} variant="body1">Bitte füllen Sie die nachfolgenden Felder vollständig aus.</Typography>
			<Typography paragraph variant="body1">Wir möchten Sie außerdem darauf hinweisen, dass die von Ihnen eingegebenen Daten von den Verantwortlichen dieser Website verarbeitet werden. Wie Ihre Daten verarbeitet werden finden Sie in unserer <Link to={ routes[ 6 ].path }>{ routes[ 6 ].title }</Link>.</Typography>
			<Box
				acceptCharset="utf-8"
				autoComplete="on"
				component="form"
				noValidate
				onSubmit={ e => e.preventDefault() }>
				<Stepper
					activeStep={ activeStep }
					orientation="vertical"
					sx={{ display: submitted && 'none', p: 0 }}>
					{ steps.map( ( label, i ) => (
						<Step key={ label }>
							<StepLabel>{ label }</StepLabel>
							<StepContent>
								{ getStepContent( i ) }
								<Box sx={{ mb: 2 }}>
									<Box>
										<Button
											disabled={ activeStep === 0 || ( shallUseSettings() && activeStep === 2 ) }
											onClick={ () => { setActiveStep( activeStep - 1 ) } }
											sx={{ mr: 1, mt: 1 }}>Zurück</Button>
										<Button
											color="primary"
											onClick={ () => {
												if ( activeStep === steps.length - 1 ) validateForm()
												setActiveStep( activeStep + 1 )
											} }
											sx={{ mr: 1, mt: 1 }}
											variant="contained">{ activeStep === steps.length - 1 ? 'Zusammenfassung anzeigen' : 'Weiter' }</Button>
									</Box>
								</Box>
							</StepContent>
						</Step>
					) ) }
				</Stepper>
				{ activeStep === steps.length &&
					!submitted ?
						<Box sx={{ mb: 2 }}>
							{ disableSubmit ?
								<Box>
									<Typography paragraph sx={{ color: 'error.main' }} variant="body2">Bitte überprüfen Sie Ihre Eingaben auf Fehler. Prüfen Sie bitte ebenfalls, ob Sie alle Pflichtfelder ausgefüllt haben.</Typography>
								</Box>
							:
								<Overview />
							}
							<Button
								onClick={ () => { setActiveStep( shallUseSettings() ? 2 : 0 ) } }
								sx={{ mr: 1, mt: 1 }}>Korrektur</Button>
							<Button
								color="primary"
								disabled={ disableSubmit }
								onClick={ e => {
									e.preventDefault()

									addParticipation()
								} }
								sx={{ mr: 1, mt: 1 }}
								variant="contained">Anmeldungen abschicken</Button>
						</Box>
					:
						<Dialog
							maxWidth="md"
							open={ submitted }>
							<DialogTitle>Wichtige Information!</DialogTitle>
							<ThankYou error={ submitError } />
							<DialogActions>
								<Button color="secondary" onClick={ () => { navigate( `/schulungen/${ eventType }` ) } }>
									Ja, ich habe verstanden
								</Button>
							</DialogActions>
						</Dialog>
				}
			</Box>
		</>
	)
}

export default RegistrationForm
